/*------------------------------------
   COLORS
----------------------------------- */
$orange: 			#eb7e1c;
$red:               #e74c3c;
$blue: 				#2d99d7;
$blue-semi-dark: 	#25658e;
$blue-semi-dark2:   #5184a5;

$blue-dark: 		#1A3547;
$blue-dark2:        #1587a7;
$black: 			#000;
$green:				#43ac6a;
$grey-dark:			#454444;
$grey:				#a8a8a8;
$grey-light:		#dfccb8;
$grey-light-extra:	#ebd7c2;
$blue-light:        rgba(102, 153, 204, .2);
$white: #fff;

$bg-color: 			#FFF;
$bg-color-header: 	$blue-dark;
$bg-color-footer: 	$blue;
$bg-subheader:		#e4e4e4;

$pdf-blue-idewe:    rgb(120, 160, 211);
$pdf-blue-light: rgb(102, 153, 204);
$pdf-blue-dark: rgb(31, 77, 115);
$pdf-blue-variant: rgb(0, 164, 206);

/*------------------------------------
   FONTS
------------------------------------ */


/* @font-face {
	font-family: 'icomoon';
	src:url('../fonts/icomoon.eot');
	src:url('../fonts/icomoon.eot?#iefix') format('embedded-opentype'),
		url('../fonts/icomoon.woff') format('woff'),
		url('../fonts/icomoon.ttf') format('truetype'),
		url('../fonts/icomoon.svg#icomoon') format('svg');
	font-weight: normal;
	font-style: normal; }
*/


$base-font-size:		16px;
$base-font-lineheight:	20px;
$base-font-family:		"proxima-nova",sans-serif;
$base-font-color:		#5D6D81;
$special-font-family:	 "kepler-std",serif;

$h1-size: 				30px;
$h1-lineheight: 		38px;
$h1-color: 				$black;	

$h2-size: 				22px;
$h2-lineheight: 		25px;
$h2-color: 				$black;	

$h3-size: 				22px;
$h3-lineheight: 		25px;
$h3-color: 				$black;	

$link-color: 			$black;
$link-text-decoration: 	underline;
$link-color-hover: 		$green;

$nav-font-size: 		20px;
$nav-bg-color:			#25658e;
$nav-bg-color-active:	$blue;

$btn-font-size: 	    14px;
$btn-color:				#fff;


/*------------------------------------
   WIDTH
----------------------------------- */

$header-height: 		140px;
$subheader-height: 		40px;
$header-height-mobile: 	 80px;
$subheader-height-mobile: 	 50px;

$wrapper-width: 		1140px;
$border-color: 			#ece8e7;
$border-radius: 		5px;