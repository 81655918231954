/*------------------------------------
    STYLE.CSS
	DAVE DRIESMANS (@davedriesmans)
	v1 - 2 january 2014
    
    DESIGN ONLY
    common styles can be moved to style-base
    
------------------------------------ */
/**
 * Setup
 */
/*------------------------------------
   COLORS
----------------------------------- */
/*------------------------------------
   FONTS
------------------------------------ */
/* @font-face {
	font-family: 'icomoon';
	src:url('../fonts/icomoon.eot');
	src:url('../fonts/icomoon.eot?#iefix') format('embedded-opentype'),
		url('../fonts/icomoon.woff') format('woff'),
		url('../fonts/icomoon.ttf') format('truetype'),
		url('../fonts/icomoon.svg#icomoon') format('svg');
	font-weight: normal;
	font-style: normal; }
*/
/*------------------------------------
   WIDTH
----------------------------------- */
/* ================================================= */
/* ------------------ 10 RESPONSIVE ------------------  */
/* ================================================= */
/* FOUNDATION LARGE COL */
@media handheld, only screen and (max-width: 60.063em) {
  .headerpic .title {
    padding-left: 3%;
  }
}

@media only screen and (max-width: 64.0624em) {
  .top-bar.expanded {
    background-color: #FFF;
  }
  .top-bar-section li:not(.has-form) a:not(.button),
  .top-bar-section li.active:not(.has-form) a:not(.button) {
    background-color: #eb7e1c;
  }
  /* nav */
  .top-bar .name {
    height: 80px;
  }
  .top-bar-section li.active:not(.has-form) a:not(.button) {
    color: white;
  }
  .top-bar-section .has-dropdown ul.dropdown {
    width: auto;
  }
  .top-bar-section a.parent-link {
    font-weight: bold;
  }
  /* nav: dropdown */
  .top-bar-section li:not(.has-form) a:not(.button),
  .top-bar-section li.active:not(.has-form) a:not(.button),
  .top-bar-section .right .dropdown li:not(.has-form) > a,
  .top-bar-section .dropdown li:not(.has-form):not(.active) > a:not(.button),
  .top-bar-section .dropdown li:not(.has-form):not(.active):hover > a:not(.button) {
    width: 100%;
  }
  .top-bar-section .has-dropdown ul.dropdown,
  .top-bar-section .right li ul.dropdown {
    left: 100%;
    width: 100%;
  }
  .top-bar-section .right li ul.dropdown,
  .top-bar-section .right li ul.dropdown {
    height: 50px;
    line-height: 50px;
    top: 0;
  }
  .top-bar-section .right li ul.dropdown li {
    min-height: 50px;
    width: 100%;
  }
}

@media handheld, only screen and (max-width: 40.063em) {
  .headerpic .headerpic-block {
    padding: 20px;
    font-size: 20px;
  }
  .headerpic .headerpic-block h3 {
    font-size: 28px;
    line-height: 27px;
  }
}

@media handheld, only screen and (max-width: 650px) {
  body:after {
    /* content: "Handheld";
    position: absolute;
    top: 0;
    left: 0;
    font-size: 18px;
    font-weight: bold;
    color: #BBB;
    background-color: yellow;
    border: 5px;
    padding: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
    opacity: 0.5; */
  }
}
